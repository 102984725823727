.Values {
  background-color: var(--black-color);
  width: 100%;
  padding-top: 10%;
  padding-bottom: 10%;
}

.value-container {
  display: flex;
  height: 100%;
  flex-direction: row;
  padding-left: 5%;
  padding-right: 5%;
  gap: 50px;
  justify-content: center;
}

.value-illustration {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.value-bubble-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.value-bubble {
  position: relative;
  background-color: var(--white-color);
  height: 300px;
  width: 300px;
  border-radius: 100%;
  border: solid 5px black;
  overflow: hidden;
}

.value-img {
  position: absolute;
  width: 70%;
  height: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.value-text {
  color: var(--white-color);
  text-align: justify;
  height: 100%;
  width: 70%;
}

.value-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.value-title {
  font-size: 50px;
}

.value-list {
  display: flex;
  flex-direction: column;
  list-style: inside;
}

ul .value-list {
  list-style: inside;
}

.value-paragraph {
  font-size: 20px;
}

@media screen and (max-width: 1200px) {
  .value-container {
    flex-direction: column;
    gap: 0;
  }

  .value-bubble-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .value-bubble {
    width: 200px;
    height: 200px;
  }

  .value-text {
    width: 100%;
  }
}
