.carousel {
  position: relative;
  overflow: hidden;
  height: auto;
  background-color: var(--white-color);
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

li {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

ul {
  padding: 0;
}

.carousel-item {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  color: white;
}

.image {
  position: relative;
  object-fit:contain;
  width: 100%;
  filter: blur(2px);
}

.image-filter {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.363);
}

.dot-container {
  display: flex;
  position: absolute;
  justify-content: space-evenly;
  bottom: 20%;
  width: 100%;
}

.unchecked-dot {
    cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: var(--black-color);
  width: 30px;
  height: 30px;
}

.checked {
  border-radius: 100%;
  background-color: var(--white-color);
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 800px) {
  .unchecked-dot {
    width: 15px;
    height: 15px;
  }

  .checked {
    width: 10px;
    height: 10px;
  }
}
