.Games {
  font-family: BSSFont;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  color: var(--black-color);
}

.title {
  font-size: xx-large;
  color: var(--black-color);
  letter-spacing: 5px;
  text-align: center;
}

.main-game-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  background-color: var(--newsbox-color);
  padding-left: 5%;
}

.image-container {
  overflow: hidden;
  width: 40%;
}

.game-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.main-game-text {
  margin-top: 25px;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
}

.button-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
}

.go-button {
  font-family: BSSFont;
  font-size: x-large;
  letter-spacing: 2px;
  background-color: var(--white-color);
  border-style: solid;
  border-radius: 10px;
  border-color: var(--black-color);
  cursor: pointer;
  min-width: 200px;
  min-height: 50px;
  transition: 0.3s;
}

.go-button:hover {
  color: var(--white-color);
  background-color: var(--black-color);
  border-color: var(--white-color);
}

.sub-game-container {
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media screen and (max-width: 1000px) {
  .main-game-container{
    display: block;
    padding: 0;
    text-align: center;
  }

  .main-game-text {
    width: 90%;
  }

  .image-container {
    width: 100%;
  }

  .button-container {
    justify-content: center;
    margin-bottom: 10px;
  }

  .go-button {
    font-size: large;
    min-width: 100px;
    min-height: 25px;
  }

  .sub-game-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5%;
  }
}