.header-container {
  font-family: BSSFont;
  color: var(--white-color);
  position: sticky;
  top: 0%;
  display: flex;
  height: 80px;
  width: 100%;
  background-color: var(--black-color);
  overflow: hidden;
  z-index: 100;
}

.bss-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-left: 2%;
  padding-right: 2%;
  justify-content: space-between;
  align-content: center;
}

.connection-container {
  display: flex;
  width: 150px;
  flex-direction: column;
  justify-content: space-evenly;
}

.connection-frame {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-style: solid;
  border-color: var(--white-color);
  padding: 5%;
}

.connection {
  font-size: large;
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.bss-title {
  margin: 0;
}
