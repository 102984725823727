.teambox-container {
    position: relative;
    height: 400px;
    width: 250px;
    background-color: var(--newsbox-color);
    overflow: hidden;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.TeamBox {
    height: 100%;
    transition: ease-in-out 0.3s;
}

.TeamBox.teambox-enter {
    filter: blur(5px);
}

.teambox-image-container {
    width: 100%;
    height: 100%;
    border: none;
}

.teambox-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.teambox-name {
    display: flex;
    flex-direction: row;
    position: absolute;
    writing-mode: vertical-rl;
    text-orientation: upright;
    height: 100%;
    top: 0px;
    left: 0px;
    justify-content: center;
    color: white;
    text-shadow: 0 0 0.5em black;
}

.TeamBoxDescription {
    position: absolute;
    opacity: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: ease-in-out 0.3s;
    justify-content: center;
}

.TeamBoxDescription.enter-done {
    opacity: 1;
}

.teambox-text {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 0 0 0.5em black;
}