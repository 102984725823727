.modal {
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(16, 20, 25, 0.849);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  pointer-events: none;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal-exit {
  opacity: 0;
}

.modal-content {
  background-color: var(--white-color);
  color: rgba(16, 20, 25);
  border-radius: 25px;
  min-height: 250px;
  width: 1000px;
  padding: 20px;
  filter: blur(0px);
  transform: translateY(-200px);
  transition: all 0.5s ease-in-out;
}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {
  transform: translateY(-200px);
}

.modal-header {
  font-size: 24px;
}

.modal-body {
  width: 100%;
  border-top: 1px solid var(--black-color);
  font-size: 24px;
}

.modal-footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.modal-close {
  cursor: pointer;
  border: 1px solid var(--black-color);
  background-color: var(--black-color);
  color: var(--white-color);
  font-size: 24px;
  width: 50%;
  border-radius: 50px;
}

@media screen and (max-width: 1000px) {
  .modal {
    top: 80px;
    width: 100%;
    height: calc(100% - 80px);
  }

  .modal-content {
    width: 100%;
    height: 100%;
  }
}
