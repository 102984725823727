.gamebox-container {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: var(--newsbox-color);
  border-radius: 5px;
}

.gamebox-title {
    position: absolute;
  margin-top: 30px;
  text-align: center;
}

.gamebox-image-container {
  max-width: 300px;
  max-height: 300px;
    object-fit: contain;
}

.gamebox-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.5s;
}

.gamebox-image:hover {
    filter: blur(5px);
}

.gamebox-button-container {
  margin-bottom: 10%;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
}

.gamebox-button-wrapper {
  display: flex;
  justify-content: space-evenly;
}
